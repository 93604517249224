import request from "@/utils/request";

// 日语APP恰学介绍列表
export function SyntaxList(params) {
    return request({
        url: '/admin/japanese_v3/syntaxList',
        method: 'get',
        params
    })
}

//日语APP语法分类
export function SyntaxType(params) {
    return request({
        url: '/admin/japanese_v3/syntaxType',
        method: 'get',
        params
    })
}


// 删除日语APP语法
export function SyntaxDel(data) {
    return request({
        url: '/admin/japanese_v3/syntaxDel',
        method: 'delete',
        data
    })
}

// 添加日语APP恰学介绍
export function SyntaxAdd(data) {
    return request({
        url: '/admin/japanese_v3/syntaxAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP语法
export function SyntaxEdit(data) {
    return request({
        url: '/admin/japanese_v3/syntaxEdit',
        method: 'put',
        data
    })
}

// 语法上传COS签名
export function getSyntaxCosToken(params) {
    return request({
        url: '/admin/japanese_v3/getSyntaxCosToken',
        method: 'get',
        params
    })
}
